<template>
  <div>
    <title>SIM INVENTORY ~ ADD ITEM DATA / MATERIAL DATA</title>
    <section class="content-header">
      <h1>
        Add item data
        <br />
        <h4>Please enter item master data</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Item master data</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Item Master Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Item code</label>
                  <input
                    type="text"
                    v-model="isidata.kodebarang"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Item code"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Name</label>
                  <input
                    type="text"
                    v-model="isidata.namabarang"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Item name"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Type</label>
                  <input
                    type="text"
                    v-model="isidata.tipebarang"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Item type"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                >
                <i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <router-link to="/barang">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterbarang",
  data() {
    return {
      loading: false,
      isidata: {
        kodebarang: "",
        namabarang: "",
        tipebarang: ""
      }
    };
  },
  methods: {
    async saveData() {
      this.loading = true;
      var kode = this.isidata.kodebarang;
      var nama = this.isidata.namabarang;
      var tipebrg = this.isidata.tipebarang;

      if (kode == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nama == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tipebrg == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item type can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          kode_barang: this.isidata.kodebarang,
          nama_barang: this.isidata.namabarang,
          tipe: this.isidata.tipebarang,
          kode_user: kodeuser
        };
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIsavebrg = "http://26.183.23.191/inventory/backend/inventory/api/barang/savebarang";
        const urlAPIsavebrg = this.$apiurl + "barang/savebarang";

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavebrg, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.isidata.kodebarang = "";
              this.isidata.namabarang = "";
              this.isidata.tipebarang = "";
              this.loading = false;
              this.$router.push({ name: "barang" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "barang" });
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
